<template>
  <v-col class="px-0 pt-0 skeleton-card" v-if="!['PosRetrieveUpdate', 'BranchRetrieveUpdate'].includes($route.name)">
    <v-row>
      <v-col v-for="action in 6" :key="action">
        <v-skeleton-loader class="rounded-md" max-width="260" height="120" type="image" />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="action in 6" :key="action">
        <v-skeleton-loader class="rounded-md" max-width="260" height="120" type="image" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-skeleton-loader class="rounded-md" type="text" />
      </v-col>
      <v-spacer />
      <v-col cols="1" class="mr-0">
        <v-skeleton-loader class="rounded-md" type="text" />
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" class="pt-0 px-1" v-else>
    <div class="d-flex">
      <div class="align-stretch">
        <v-skeleton-loader class="bgPanel rounded-t-md rounded-b-md" type="image, article" width="380" height="calc(100vh - 20vh)" />
      </div>
      <div class="flex-grow-1 ml-2">
        <div class="d-flex">
          <div class="px-1 flex-grow-1" v-for="item in 3" :key="item">
            <v-skeleton-loader class="rounded-md" transition="fade-transition" type="image" />
          </div>
        </div>
        <div class="d-block">
          <v-skeleton-loader class="mt-2 px-1 rounded-md" transition="fade-transition" type="image" height="100" />
          <v-skeleton-loader class="mt-2 px-1 rounded-md" transition="fade-transition" type="image" height="100" />
        </div>
      </div>
    </div>
  </v-col>
</template>
<script>
  export default {
    data: () => ({
      attrs: {
        boilerplate: true,
        elevation: 0
      }
    })
  }
</script>