<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Tiendas" :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasPermission(['view_branch'])">
        <!-- <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2" v-bind="attrs" v-on="on" outlined :ripple="false" :disabled="branchesList.length === 0">
              <span class="mr-1 body-1 font-weight-bold">Ordenar por:</span>
              <span class="body-1 text-lowercase">{{currentSort.label}}</span>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-5 py-1" :ripple="false" v-for="sort in sortList" :key="sort.id" @click="currentSort=sort">
              <v-list-item-title class="subtitle-2 text--secondary font-weight-medium">
                {{sort.label}}
                <v-icon color="secondary" size="20" right>{{ sort.id.search('ascending') ? 'mdi-arrow-down' : 'mdi-arrow-up'}}</v-icon>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-query-builder :query-map="filters" model="branches" :active.sync="activeFilter" />
        <!-- <v-btn @click="dialogBranch = true" color="primary"><v-icon left>mdi-store</v-icon>Crear tienda</v-btn> -->
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="px-0">
      <PermissionDenied v-if="!$helpers.hasPermission(['view_branch'])" />
      <template v-else>
        <VSkeleton v-if="loading" />
        <template v-else>
          <!-- branch list -->
          <v-col cols="12" class="px-0 pt-0">
            <v-empty-state v-if="!branchesList.length || Object.keys($route?.query).length === 0" type="branch" id="tiendas" customClass="mt-3" :hiddenDescription="hiddenBtnEmpty">
              <template v-slot:actions>
                <template v-if="!$route.query.ordering && hiddenBtnEmpty">
                  <v-btn @click="activeFilter=true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                  <v-btn class="ml-2" @click="latestRecords('-created')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Ver tiendas</v-btn>
                </template>
              </template>
            </v-empty-state>
            <template v-else>
              <VCardList class="pb-16" :list="branchesList" :complements="posList" />
              <v-col class="pa-0">
                <VMainPagination :count="count" />
              </v-col>
            </template>
          </v-col>
          <!-- end branch list -->
        </template>
      </template>
    </v-col>

    <!-- dialog new branch -->
    <v-dialog v-model="dialogBranch" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar class="px-4" color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear nueva tienda</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogBranch=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-0 px-4">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model.trim="$v.newBranch.name.$model" :error="$v.newBranch.name.$error" outlined required single-line dense placeholder="Acme LLC" maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Dirección</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.newBranch.address.$model" :error="$v.newBranch.address.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>{{$t('generals.Comuna')}}</v-list-item-title>
              <v-list-item-subtitle v-if="$store.getters['base/currentStates']">
                <v-select v-model="$v.newBranch.state.$model" :error="$v.newBranch.state.$error" :label="$t('generals.Selecciona una comuna')" :items="$store.getters['base/currentStates'] || []" item-value="id" item-text="name" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogBranch=false">Cancelar</v-btn>
          <v-btn color="primary" @click="createBranch">Crear nueva tienda</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new branch -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import VCardList from '@/modules/pos/pos/components/complements/VCardList'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import VHeader from '@/modules/pos/pos/components/complements/VHeader'
import ListViewMixin from '@/mixins/ListViewMixin'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    VEmptyState,
    VCardList,
    VSkeleton,
    VHeader,
    VQueryBuilder,
    VMainPagination,
    MiniHeader,
    PermissionDenied
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    activeFilter: false,
    scroll: 0,
    loading: false,
    chipStatus: {
      active: {
        name: 'Activo',
        icon: 'mdi-check',
        color: 'lightBlue'
      },
      issued: {
        name: 'Con problemas',
        icon: 'mdi-close',
        color: 'redLight'
      },
      off: {
        name: 'Apagado',
        icon: 'mdi-cloud-off-outline',
        color: 'greyMedium'
      }
    },
    filters: [
      {
        id: 'name',
        label: 'Nombre',
        operators: ['contiene'],
        type: 'text',
        category: 'a',
        value: null
      },
      {
        id: 'state',
        label: 'Comuna',
        choices: [],
        type: 'select',
        category: 'b',
        value: null
      }
    ],
    dialogBranch: false,
    newBranch: {
      name: '',
      state: '',
      address: ''
    },
    currentSort: {
      label: 'Nombre',
      id: 'ascending_name'
    },
    sortList: [
      {
        label: 'Nombre',
        id: 'ascending_name'
      },
      {
        label: 'Nombre',
        id: 'descending _name'
      },
      {
        label: 'Comuna',
        id: 'ascending_state'
      },
      {
        label: 'Comuna',
        id: 'descending _state'
      }
    ],
    breadcrumbs: {
      main: 'POS',
      children: [
        {
          text: 'Tiendas'
        }
      ]
    },
    branchesList: [],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    ...mapState({
      count: state => state.branches.branchesCount,
      posList: state => state.pos.posList
    }),
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.branchesList.length) return false
      return false
    }
  },
  created () {
    this.setFilterComuna()
    if (Object.keys(this.$route.query).length && this.$helpers.hasPermission(['view_branch'])) this.getList()
    this.filters[1].label = this.$t('generals.Comuna')
  },
   methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    setFilterComuna () {
      const index = this.filters.findIndex(({id}) => id === 'state')
      this.filters[index].choices = this.$store.getters['base/currentStates'].map(({name, id}) => {
        return {
          label: name,
          value: id
        }
      })
    },
    getList () {
      this.loading = true
      this.$store.dispatch('branches/LIST', {
        resource: 'branches',
        query: this.$route.query
      })
      .then((resp) => {
        this.branchesList = resp.data.results
        if (this.$helpers.hasPermission(['view_pos'])) {
          this.$store.dispatch('pos/LIST', {
            resource: 'pos',
            query: this.$route.query
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    createBranch () {
      this.$v.newBranch.$touch()
      if (this.$v.newBranch.$invalid) {
        return false
      }
      this.$store.dispatch('branches/CREATE', {
        resource: 'branches',
        payload: this.newBranch
      })
      .then(() => {
        this.$dialog.message.info('Tienda guardada correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogBranch = false
      })
    }
  },
  validations: {
    newBranch: {
      name: { required },
      state: { required },
      address: { required, maxLength: maxLength(254) }
    }
  }
}
</script>